@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Share+Tech+Mono&family=Kaushan+Script&display=swap");

:root {
  --champagne-pink: #dacac4ff;
  --melon: #f8afa6ff;
  --honeydew: #dae7ddff;
  --eerie-black: #212529ff;
  --antiflash-white: #efefefff;
  --antiflash-white2: #f0f2f3ff;
  --misty-rose: #fadcd9ff;
  --rosy-brown: #c8a0a0ff;
  --taupe-gray: #93939bff;
  --rose-shadow: #E49789FF;
}

html,
body,
#root,
.app {
  height: 100%;
  background-color: var(--antiflash-white);
  color: var(--eerie-black);
  @apply: var(--eerie-black);
}

.logo {
  color: var(--melon);
  text-shadow: 1px 1px var(--rose-shadow);
}

.krausyd {
  color: var(--melon); 
}